.select-xs .select__single-value, .select-xs .select__placeholder, .select-xs .select__menu {
  @apply font-figtree text-gray-900 text-[14px] leading-[19.6px];
}

.select-xs .select__multi-value .select__multi-value__label {
  @apply font-figtree text-gray-900 text-[14px] leading-[19.6px] font-semibold;
}

.select-sm .select__single-value, .select-sm .select__placeholder, .select-sm .select__menu {
  @apply font-figtree text-gray-900 text-[14px] leading-[19.6px];
}

.select-sm .select__multi-value .select__multi-value__label {
  @apply font-figtree text-gray-900 text-[14px] leading-[19.6px] font-semibold;
}

.select-md .select__single-value, .select-md .select__placeholder, .select-md .select__menu {
  @apply font-figtree text-gray-900 text-[15px] leading-[21px];
}

.select-md .select__multi-value .select__multi-value__label {
  @apply font-figtree text-gray-900 text-[15px] leading-[21px] font-semibold;
}

.select-lg .select__single-value, .select-lg .select__placeholder, .select-lg .select__menu {
  @apply font-figtree text-gray-900 text-[18px] leading-[23px];
}

.select-lg .select__multi-value .select__multi-value__label {
  @apply font-figtree text-gray-900 text-[18px] leading-[23px] font-semibold;
}

.select__control--is-disabled {
  @apply bg-gray-100 text-gray-400 cursor-not-allowed;
}

.select__control--is-disabled .select__placeholder {
  @apply text-gray-light;
}

.select__control--is-disabled .select__multi-value {
  @apply opacity-60;
}

.select__placeholder {
  @apply !text-gray;
}

.select__value-container {
  @apply !p-0;
}

.select__multi-value {
  @apply !bg-gray-200 text-center !rounded-[14px] pl-[14px] pr-[12px] py-[4px] pb-[3px] !mr-[4px] flex items-center justify-center;
}

.select__multi-value .select__multi-value__label {
  @apply p-0;
}

.select__multi-value__remove {
  @apply hover:!bg-gray-light hover:!text-gray-900 ml-[8px] !p-0 w-[18px] h-[18px] !rounded-[50%] flex items-center justify-center;
}

.select__single-value {
  color: inherit;
}

.select__single-value--is-disabled {
  @apply opacity-70;
}

.select__control {
  @apply h-auto !rounded-[10px] !bg-gray-50 !cursor-pointer !border-gray-light hover:!border-gray-light;
}

.select__control--is-disabled {
  @apply !cursor-not-allowed pointer-events-auto opacity-50;
}

.select-sm .select__control {
  @apply pl-[8px] pr-[0px] py-[3px];
}

.select-md .select__control {
  @apply pl-[8px] pr-[0px] py-[5px];
}

.select-lg .select__control {
  @apply pl-[10px] pr-[0px] py-[8px];
}

.select__control--is-focused {
  @apply !border-brand hover:!border-brand !shadow-brand;
}

.select__control .hide-single-value .select__single-value {
  @apply hidden;
}

.select__menu {
  @apply bg-white;
}

.select-option {
  @apply flex items-center justify-between px-2 py-1.5 hover:bg-gray-50 cursor-pointer;
}

.select-option .selected {
  @apply bg-gray-100;
}

.select .disabled {
  @apply !opacity-40 cursor-not-allowed;
}

.select-dropdown-indicator {
  @apply p-2;
}

.select-clear-indicator {
  @apply p-2;
}

.select__group-heading {
  @apply font-figtree !text-gray-900 !tracking-[1.3px] !uppercase !font-semibold;
}

.select.invalid .select__control {
  @apply !border-red;
}

.select.invalid .select__control--is-focused {
  @apply !border-red !shadow-[0_0_0_1px_rgb(239,68,68)];
}

.select-xs .select__control {
  @apply min-h-[30px] h-[30px];
}

.select-xs .select-dropdown-indicator {
  @apply py-0;
}

.select-xs .select__menu {
  @apply rounded-[10px] py-2;
}

.select-xs .select__control {
  @apply pl-[8px] pr-[0px] py-[0] border-[1px];
}

.select-xs .select__input-container {
  @apply p-0 m-[1.5px];
}

.select-xs .select-clear-indicator {
  @apply p-0;
}
