.input-group {
    @apply flex items-center;
}

.input {
    @apply z-10;
}

.input {}

.input:focus,
.input:focus-within {
    @apply z-20;
}

.date-picker {
    z-index: 11;
}

.select__control--is-focused,
.date-picker.picker-expanded,
.input-suffix-end,
.input-suffix-start {
    @apply z-20;
}

.input-group > .input,
.input-group > .input-addon,
.input-group > .button {}

.input-group > .button:first-child {
    @apply ltr:rounded-r-none rtl:rounded-l-none;
}

.input-group > .button:last-child:not(.picker) {
    @apply ltr:rounded-l-none rtl:rounded-r-none;
}

.input-group > .button:not(:first-child) {
    @apply ltr:rounded-l-none ltr:-ml-px rtl:rounded-r-none rtl:-mr-px;
}

.input-group > .button:not(:last-child) {
    @apply ltr:rounded-r-none rtl:rounded-l-none;
}

.input-group > * {}

.input-group > *:first-child {}
.input-group > *:first-child.input,
.input-group > *:first-child.select__control {
    @apply ltr:rounded-r-none rtl:rounded-l-none;
}
.input-group > *:last-child {}
.input-group > *:last-child.input,
.input-group > *:last-child.select__control {
    @apply ltr:rounded-l-none rtl:rounded-r-none;
}

.input-group > *:not(:first-child) {}
.input-group > *:not(:first-child).input,
.input-group > *:not(:first-child).select__control {
    @apply ltr:rounded-l-none ltr:-ml-px rtl:rounded-r-none rtl:-mr-px;
}

.input-group > *:not(:last-child) {}
.input-group > *:not(:last-child) .input,
.input-group > *:not(:last-child) .select__control {
    @apply ltr:rounded-r-none rtl:rounded-l-none;
}

