.ProseMirror {
  /*@apply caret-black dark:caret-white outline-0 w-full pl-[90px] xl:pr-[90px] pb-[10px] z-0 max-w-[1130px] xl:max-w-[1220px];*/

  .selection {
    @apply inline;
  }

  .selection,
  *::selection {
    @apply bg-black/10 dark:bg-white/20;
  }

  & > .react-renderer {
    @apply my-[16px] first:mt-0 last:mb-0;
  }

  &.resize-cursor {
    @apply cursor-col-resize;
  }

  .ProseMirror-gapcursor {
    @apply relative w-full max-w-2xl mx-auto;

    &:after {
      @apply border-t-black/40 dark:border-t-white/40 w-full -top-[1.5em] max-w-2xl mx-auto left-0 right-0;
    }
  }
}

[data-theme='slash-command'] {
  width: 1000vw;
}

@import './partials/animations.css';
@import './partials/blocks.css';
@import './partials/code.css';
@import './partials/collab.css';
@import './partials/lists.css';
@import './partials/placeholder.css';
@import './partials/table.css';
@import './partials/typography.css';
