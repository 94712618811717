.input {
    @apply
    border
    border-gray-light
    rounded-md
    w-full
    py-2 px-3
    focus:outline-none
    focus:ring-1
    focus-within:ring-1
    appearance-none
    transition
    duration-150
    ease-in-out;
}


.input::placeholder {
    @apply text-gray-400;
}

.input.input-invalid {
    @apply ring-1 ring-red border-red;
}

.input.input-lg {
    @apply text-lg;
}

.input-addon {
    @apply flex items-center px-4 rounded-md border border-gray-light bg-gray-50;
}

.input-textarea {
    min-height: 7rem;
}

.input-wrapper {
    @apply relative w-full flex;
}

.input-disabled {
    @apply bg-gray-100 text-gray-400 cursor-not-allowed;
}

.input-disabled::placeholder {
    @apply opacity-70;
}

.input-suffix-start {
    @apply absolute top-2/4 transform -translate-y-2/4 ltr:left-2.5 rtl:right-2.5;
}

.input-suffix-end {
    @apply absolute top-2/4 transform -translate-y-2/4 ltr:right-2.5 rtl:left-2.5 flex;
}

.input-suffix {
    @apply pr-3.5;
}

.input-suffix.x-button {
    @apply pr-[9px];
}