.table-default {
  @apply table-auto min-w-full divide-y divide-gray-200 bg-white;
}
.table-default thead > tr > th {
  @apply px-6 py-2 text-left rtl:text-right text-xs font-semibold uppercase tracking-wider text-gray;
}
.table-default tbody {
  @apply divide-y divide-gray-200;
}
.table-default tbody > tr > td {
  @apply px-6 py-4;
}
.table-default tfoot > tr > td {
  @apply px-6 py-3 border-t border-gray-200;
}
.table-default.borderless-row tbody {
  @apply divide-none;
}
.table-flex {
  @apply min-w-full divide-y divide-gray-200;
}
.table-flex .thead {
  @apply bg-white;
}
.table-flex .thead .tr .th {
  @apply px-6 py-2 text-left text-xs font-semibold uppercase tracking-wider text-gray;
}
.table-flex .tbody {
  @apply divide-y divide-gray-200;
}
.table-flex .tbody .tr .td {
  @apply px-6 py-4;
}
.table-flex .tfoot .tr .td {
  @apply px-6 py-3 border-t;
}
.table-compact thead > tr > th {
  @apply px-6 py-2 text-xs;
}
.table-compact tbody > tr > td {
  @apply px-6 py-2;
}
.table-compact .thead > .tr > .th {
  @apply px-6 py-2 text-xs;
}
.table-compact .tbody > .tr > .td {
  @apply px-6 py-2;
}
.table-hover > tbody > tr:hover > * {
  @apply bg-gray-100/50;
}
.table-hover > .tbody > .tr:hover > * {
  @apply bg-gray-100/50;
}
th, td {
  @apply font-figtree text-gray-900 text-[14px] leading-[19.6px] max-w-[225px];
}
.row-selectable * {
  cursor: pointer;
}
