.notification {
    @apply bg-white rounded-lg mb-3 relative border border-gray-100;
}

.notification-content {
    @apply p-5 flex;
}

.notification-content.no-child {
    @apply items-center;
}

.notification-title {
    @apply font-semibold text-gray-900;
}

.notification-close {
    @apply top-4 right-4;
}