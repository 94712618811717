.ProseMirror {
  p {
    @apply first:mt-0 last:mb-0;
  }

  & > p {
    @apply first:mt-0 last:mb-0;
  }

  figure[data-type='blockquoteFigure'],
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply first:mt-0 last:mb-0;
  }

  /*a.link {
    @apply text-blue font-extrabold dark:text-blue-400;
  }*/

  mark {
    @apply bg-red rounded-sm decoration-clone text-inherit py-1 px-0 dark:bg-red-400;
  }

  & img {
    @apply h-auto w-full max-w-full;
  }

  [data-type='horizontalRule'] {
    @apply my-8 py-4 transition-all duration-100 ease-in-out cursor-pointer;

    &.ProseMirror-selectednode {
      @apply bg-black/5 dark:bg-white/10;

      hr {
        @apply border-t-black/30 dark:border-t-white/30;
      }
    }

    &:hover:not(&.ProseMirror-selectednode) {
      @apply bg-black/5;
      @apply dark:bg-white/10;
    }

    hr {
      @apply border-0 border-t border-black/20 bg-black/80;
      @apply dark:border-white/20 dark:bg-white/80;
    }
  }
}
